:root, [data-theme=light], [data-theme=dark] {
  --border-radius-sm: 14px;
  --border-radius: 16px;
  --border-radius-md: 18px;
  --border-radius-lg: 22px;
  --primary-color: var(--green)
  --page-max-width: 100% !important;
}

input.form-control::before, select.form-control::before {
  content: "@";
}

.frappe-control:not([data-fieldtype=MultiSelectPills]):not([data-fieldtype="Table MultiSelect"]).has-error input {
  border: unset;
  border-bottom: 2px solid var(--error-border);
  border-top: 1px outset var(--error-border);
}

[data-theme=light] .form-control, [data-theme=dark] .form-control {
  box-shadow: none;
  background-color: var(--control-bg);
  height: auto;
  margin-bottom: 7px;
  border-radius: 20px;
}
[data-theme=light] .form-control.bold, [data-theme=dark] .form-control.bold {
  box-shadow: none;
}
[data-theme=light] .form-control:focus, [data-theme=dark] .form-control:focus {
  border-bottom: 2px solid var(--primary-color);
  border-top: 1px outset var(--primary-color);
  box-shadow: none;
}

.section-head, .section-body {
  max-width: 100% !important;
}

[data-theme=light] .form-control {
  border-bottom: 2px solid var(--gray-200);
  border-top: 1px outset var(--gray-200);
}

input[type=checkbox]:focus {
  box-shadow: none;
}
input[type=checkbox]:checked {
  background-color: var(--control-bg);
  background-image: none;
  background-size: 57%, 100%;
  box-shadow: none;
  border: none;
}
input[type=checkbox]:checked:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 12px;
  border: 2px solid transparent;
  border-right-color: var(--primary-color);
  border-bottom-color: var(--primary-color);
  transform: rotate(40deg);
  transform-origin: 100% 100%;
  background: var(--control-bg);
}

.multiselect-list, dropdown, input-xs {
  height: 1rem;
}
.multiselect-list .form-control, dropdown .form-control, input-xs .form-control {
  height: 35px;
}

.btn-primary:active {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary:focus {
  box-shadow: var(--primary-color) !important;
}

.list-subject .level-item span[title=Likes] {
  display: none;
}
.list-subject .level-item .like-action {
  display: none;
}

.form-stats-likes, .form-stats-likes + span.mx-1 {
  display: none;
}

.modal-body .frappe-control[data-fieldtype=Phone] .selected-phone {
  top: 54%;
}

.frappe-control[data-fieldtype=Select] select.form-control {
  padding: var(--input-padding);
}

.switch-lan {
  width: 70px;
  height: 20px;
  padding-top: 4px;
  padding-left: 10px;
  padding-bottom: 2rem;
}
.switch-lan .switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 23px;
  height: 22px;
  padding: 3.4px;
  border-radius: 18px;
  cursor: pointer;
}
.switch-lan .switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-lan .switch-label {
  position: relative;
  display: block;
  height: 22px;
  width: 3.5rem;
  font-size: 10px;
  text-transform: uppercase;
  background: var(--green);
  border-radius: inherit;
  box-shadow: 0.5px 0.5px 4px 1px var(--green);
}
.switch-lan .switch-label:before, .switch-lan .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
}
.switch-lan .switch-label:before {
  content: attr(data-off);
  right: 5px;
  color: var(--dt-light-bg);
  font-size: 0.7rem;
}
.switch-lan .switch-label:after {
  content: attr(data-on);
  left: 5px;
  color: var(--modal-bg);
  opacity: 0;
}
.switch-lan .switch-input:checked ~ .switch-label {
  background: var(--primary-color);
}
.switch-lan .switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-lan .switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-lan .switch-handle {
  position: absolute;
  top: 2px;
  left: 3px;
  width: 25px;
  height: 24px;
  background-color: var(--control-bg);
  border-radius: 100%;
}
.switch-lan .switch-input:checked ~ .switch-handle {
  left: 35px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-lan .switch-label, .switch-lan .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.navbar .awesomplete .search-bar {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  top: 4px;
  left: 11%;
}
.navbar .awesomplete .searchbar {
  height: 35px;
  width: 0rem !important;
  background-color: var(--bg-color);
  padding-left: 12px;
  border-bottom-left-radius: none;
}
.navbar .awesomplete .transform {
  transition: all 0.5s ease;
}
.navbar .awesomplete .transform:focus {
  width: 17rem !important;
}
.navbar .awesomplete .awesomplete-list {
  width: 81%;
  top: 38px;
  left: 16%;
}
.navbar .awesomplete .search-icon {
  height: 33px;
  right: -5px;
  width: 34px;
  background-color: var(--navbar-bg);
  cursor: pointer;
}
.navbar .awesomplete .search-icon .fa {
  position: relative;
  left: 8px;
}
@media (max-width: 991px) {
  .navbar .awesomplete .transform:focus {
    width: 11rem !important;
  }
  .navbar .awesomplete .awesomplete-list {
    width: 10rem;
  }
}

[data-theme=light] .navbar .awesomplete .form-control, [data-theme=dark] .navbar .awesomplete .form-control {
  box-shadow: none;
  background-color: var(--navbar-bg);
  height: auto;
  margin-bottom: 7px;
  border-radius: none;
  border: none;
  border-bottom: 2px solid var(--primary-color);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
[data-theme=light] .navbar .awesomplete .form-control.bold, [data-theme=dark] .navbar .awesomplete .form-control.bold {
  box-shadow: none;
}
[data-theme=light] .navbar .awesomplete .form-control:focus, [data-theme=dark] .navbar .awesomplete .form-control:focus {
  border-bottom: 2px solid var(--primary-color);
  box-shadow: none;
  border-radius: none;
}
[data-theme=light] .navbar .awesomplete .form-control:active, [data-theme=dark] .navbar .awesomplete .form-control:active {
  border-bottom: 2px solid var(--primary-color);
  border-radius: none;
}